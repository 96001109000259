import * as React from 'react';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { isLoading, openSnackbar } from '../../../../../redux/action/defaultActions';
import { useEffect, useState } from 'react';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const DeleteMain = () => {
    const actionDispatcher = useDispatch();
    const [mainData, setMainData] = useState([])

    // const handleDelete = (id) => () => {

    //     // setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    // };
    //   *******************************Handle delete Data Quesion Answer FAQ************************************

    const handleDelete = async (id) => {

        actionDispatcher(isLoading(true));
        try {
            const response = await axios.delete(`/api/v1/admin/delete/main/category/${id}`);
            actionDispatcher(isLoading(false));
            // handleOpen(); // Close the modal after successful deletion
            getMainData(); // Refresh the FAQ data
            actionDispatcher(openSnackbar(response?.data?.message, "success"));
        } catch (error) {
            actionDispatcher(isLoading(false));
            console.error("Error uploading image:", error);
            actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
        }



    };
    //   ***********************************************************************************************

    //   ******************************* Handle Get Data Main Category ************************************
    const getMainData = async () => {
        try {
            const response = await axios.get("/api/v1/admin/get/main/categories");
            // console.log("mainCategory====>", response?.data);
            setMainData(response?.data?.allMainCategories);
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };
    //   ***********************************************************************************************

    useEffect(() => {
        getMainData();
    }, []);

    return (
        <Paper
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >
            {mainData?.map((data) => {
                let icon;

                // if (data.label === 'React') {
                //     icon = <TagFacesIcon />;
                // }

                return (
                    <ListItem key={data?._id}>
                        <Chip
                            // icon={icon}
                            label={data?.category}
                            onDelete={() => handleDelete(data?._id
                            )}
                        // onDelete={data?.category === 'React' ? undefined : handleDelete(data)}
                        />
                    </ListItem>
                );
            })}
        </Paper>
    );
}


export default DeleteMain;

