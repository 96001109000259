import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./HorizontalScroller.css";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import { Card } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import "../../../../layouts/authentication/sign-up/signUp.css";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../../redux/action/defaultActions";

const HorizontalScroller = ({ setPageChange, setCategory, handleCategory }) => {
  const actionDispatcher = useDispatch();
  const [sellCategory, setSellCategory] = useState([]);

  // ************************** Get Selling Scroller data *******************************

  const getSellCategory = async () => {
    actionDispatcher(isLoading(true));

    try {
      const res = await axios.get(`/api/v1/admin/get/selling/items/sub/categories`);
      actionDispatcher(isLoading(false));
      setSellCategory(res?.data?.allMainCategories);
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.log("error", error);
    }
  };
  // **************************************************************************************

  // ***************************** PageChange Function **********************************
  const handlePageChange = (name) => {
    setPageChange(1);
    setCategory(name);
    handleCategory(name);
  };
  // ===============================================================================================

  useEffect(() => {
    getSellCategory();
  }, []);

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 5,
  //     slidesToSlide: 5, // optional, default to 1.
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 464 },
  //     items: 3,
  //     slidesToSlide: 3, // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 2,
  //     slidesToSlide: 2, // optional, default to 1.
  //   },
  // };
  return (
    <Card
      sx={{
        marginRight: "1.5rem",
        backgroundColor: "white",
        paddingY: "1rem",
        minHeight: "100vh",
        maxHeight: "100%",
      }}
    >
      <MenuList>
        <MenuItem
          textTransform={"capitalize"}
          onClick={() => {
            handlePageChange("all");
          }}
        >
          All
        </MenuItem>
        {sellCategory?.map((data, index) => {
          return (
            <MenuItem
              key={index}
              textTransform={"capitalize"}
              onClick={() => {
                handlePageChange(data?.category);
              }}
            >
              {data?.category}
            </MenuItem>
          );
        })}
      </MenuList>
    </Card>
  );
};

export default HorizontalScroller;

HorizontalScroller.propTypes = {
  handleCategory: PropTypes.func,
  setPageChange: PropTypes.func,
  setCategory: PropTypes.any,
};
