import { Box, CardContent, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import axios from "axios";

// Style of Modal section set in center
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  maxHeight: "90vh",
  overflowY: "auto",
  p: 2,
};
// ******************************

const SingleUserDetails = ({ open, handleOpen, userId }) => {
  const [data, setData] = useState("");

  //   // ****************************Transaction Single UsER Details Section*********************************
  const handleTransactionDetails = async (userId) => {
    try {
      const res = await axios.get(`/api/v1/admin/get/user/data/${userId}`);
      // console.log("TransactionSingleUsers===>", res?.data);

      setData(res?.data?.userData);
      // return res.data.allUsers;
    } catch (error) {
      console.log("error===>", error);
    }
  };
  //   // ****************************************************************************************

  useEffect(() => {
    if (userId) {
      handleTransactionDetails(userId);
    }
  }, []);

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            />
          </Box>
          <CardContent
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60%",
            }}
          >
            <img
              src={data?.image?.imageUrl}
              alt={data?.userName}
              style={{
                borderRadius: "10px",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
          </CardContent>
          <CardContent>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h6" component="div">
                Name :
              </Typography>
              <Typography variant="h6" component="div" color="text.secondary">
                {data?.userName}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h6" component="div">
                Email :
              </Typography>
              <Typography variant="h6" component="div" color="text.secondary">
                {data?.email}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h6" component="div">
                Address :
              </Typography>
              <Typography variant="h6" component="div" color="text.secondary">
                {data && data?.addresses.length > 0 ? data?.addresses[0]?.wardName : ""} &nbsp;
                {data && data?.addresses.length > 0 ? data?.addresses[0]?.wardNumber : ""}
                {/* {data?.address?.wardName} {data?.address?.wardNumber} */}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h6" component="div">
                CreatedAt :
              </Typography>
              <Typography variant="h6" component="div" color="text.secondary">
                {dayjs(data?.createdAt).format("DD/MM/YY")}
              </Typography>
            </Box>
          </CardContent>
        </Box>
      </Modal>
    </Box>
  );
};

SingleUserDetails.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
  userId: PropTypes.string,
};

export default SingleUserDetails;
