// import React from "react";

// const AddvertisementSingle = () => {
//   return <div></div>;
// };

// export default AddvertisementSingle;

import { Box, CardContent, Chip, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import Sun from "@mui/icons-material/LightMode";
import ArgonButton from "components/ArgonButton";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import axios from "axios";
import ArgonInput from "components/ArgonInput";
import AdvertisementTableData from "./AdvertisementTableData";
import dayjs from "dayjs";

// Style of Modal section set in center
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflowY: "auto",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};
const styleBtn = {
  margin: "1rem",
  bgcolor: "#42d7f5 !important",
  color: "white !important",
};
// ******************************

const AddvertisementSingle = ({ open, handleOpen, data }) => {
  const actionDispatcher = useDispatch();
  const [statusOpen, setStatusOpen] = useState(false);
  const [reason, setReason] = useState();

  // ***********************************Open Modal State change****************************************
  const handleClose = () => setStatusOpen(!statusOpen);
  // ***************************************************************************

  //   // **********************Advertisement UsER  Status Action Section**************************
  const handleEditClick = async (userId, status) => {
    actionDispatcher(isLoading(true));

    try {
      if (status === "approved") {
        const res = await axios.put(
          `/api/v1/admin/update/advertisement/approval/status/${userId}`,
          {
            status,
          }
        );
        actionDispatcher(openSnackbar(res?.data?.message, "success"));
        window.location.reload();
      } else {
        const res = await axios.put(
          `/api/v1/admin/update/advertisement/approval/status/${userId}`,
          {
            status,
            reason,
          }
        );
        actionDispatcher(openSnackbar(res?.data?.message, "success"));
        window.location.reload();
      }
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.log("error===>", error);
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
    }
  };

  //   // ******************************************************************************
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} overflowy={"auto"}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            />
          </Box>
          {data?.advertisementImage ? (
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "20rem",
                // objectFit: "cover",
                marginBottom: "1rem",
              }}
            >
              <img
                src={data?.advertisementImage?.imageUrl}
                alt={data?.userName}
                style={{
                  borderRadius: "5px",
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            </CardContent>
          ) : (
            ""
          )}

          <CardContent>
            <Grid container>
              <Grid item container xs={12} sm={12} md={12} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Advertisement Category :
                </Typography>

                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.advertisementCategory}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Advertisement Type :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.advertisementType}
                </Typography>
              </Grid>
              {data?.advertisementType === "website" || data?.advertisementType === "Biz Page" ? (
                <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                  <Typography variant="h6" component="div">
                    Title :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    {data?.title}
                  </Typography>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Budget :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.budget}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Status :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.status}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Gender :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.gender}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Schedule :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.schedule}
                </Typography>
              </Grid>

              {data?.schedule === "start later" ? (
                <>
                  <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                    <Typography variant="h6" component="div">
                      Starting Date:
                    </Typography>
                    <Typography variant="h6" component="div" color="text.secondary">
                      {/* {data?.createdAt} */}
                      {dayjs(data?.scheduleStartDate).format("DD/MM/YY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                    <Typography variant="h6" component="div">
                      Ending Date:
                    </Typography>
                    <Typography variant="h6" component="div" color="text.secondary">
                      {/* {data?.createdAt} */}
                      {dayjs(data?.scheduleEndDate).format("DD/MM/YY")}
                    </Typography>
                  </Grid>
                </>
              ) : (
                // "scheduleDays"
                <>
                  <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                    <Typography variant="h6" component="div">
                      Schedule Days:
                    </Typography>
                    <Typography variant="h6" component="div" color="text.secondary">
                      {/* {data?.address?.city} {data?.address?.state} {data?.address?.country} */}
                      {data?.scheduleDays}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                    <Typography variant="h6" component="div">
                      Starting From:
                    </Typography>
                    <Typography variant="h6" component="div" color="text.secondary">
                      {/* {data?.address?.city} {data?.address?.state} {data?.address?.country} */}
                      {dayjs(data?.createdAt).format("DD/MM/YY")}
                    </Typography>
                  </Grid>
                </>
              )}

              {/* {data?.scheduleEndDate ? (
                <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                  <Typography variant="h6" component="div">
                    Schedule Date :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    {data?.budget}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                  <Typography variant="h6" component="div">
                    Schedule Days:
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    {data?.scheduleDays}
                  </Typography>
                </Grid>
              )} */}

              {data?.locationSelectionType === "manual select" ? (
                <Grid item xs={12} sm={12} md={12} display={"flex"}>
                  <Typography variant="h6" component="div">
                    Locations:
                  </Typography>
                  {data?.locations?.map((data, index) => {
                    return (
                      <Chip
                        overflow={"auto"}
                        key={index}
                        variant="soft"
                        startDecorator={<Sun />}
                        label={data}
                      />
                    );
                  })}
                </Grid>
              ) : (
                ""
              )}
              {data?.status === "pending" ? (
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  display={"flex"}
                  justifyContent={"center"}
                  alignContent={"center"}
                  overflow={"auto"}
                  // spacing={2}
                >
                  <ArgonButton
                    variant="gradient"
                    color="success"
                    sx={{ margin: "1rem" }}
                    onClick={() => {
                      handleEditClick(data?._id, "approved");
                    }}
                  >
                    Accept
                  </ArgonButton>

                  <ArgonButton
                    variant="gradient"
                    color="success"
                    sx={{ margin: "1rem" }}
                    onClick={() => {
                      handleClose(data?._id, "rejected");
                    }}
                  >
                    Reject
                  </ArgonButton>
                </Grid>
              ) : (
                ""
              )}

              <Modal
                open={statusOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <Typography id="modal-modal-title" variant="h5" component="h2">
                    Reason
                  </Typography>
                  <ArgonInput
                    type="text"
                    multiline
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  >
                    {" "}
                  </ArgonInput>
                  <ArgonButton
                    alignItems={"center"}
                    variant="gradient"
                    color="success"
                    sx={{ margin: "1rem" }}
                    onClick={() => {
                      handleEditClick(data?._id, "rejected");
                    }}
                  >
                    Submit
                  </ArgonButton>
                </Box>
              </Modal>
            </Grid>
          </CardContent>
        </Box>
      </Modal>
    </Box>
  );
};

AddvertisementSingle.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  data: PropTypes.object,
  rowsPerPage: PropTypes.any,
  page: PropTypes.any,
};

export default AddvertisementSingle;
