export const ActionTypes = {
  ADMIN_LOGIN: "ADMIN_LOGIN",
  ADMIN_LOGOUT: "ADMIN_LOGOUT",
  ADMIN_AUTH: "ADMIN_AUTH",

  OPEN_SNACKBAR: "OPEN_SNACKBAR",
  CLOSE_SNACKBAR: "CLOSE_SNACKBAR",

  SET_ISLOADING: "SET_OPEN_ISLOADING",

  SET_TEMP_PATH: "SET_TEMP_PATH",

  SEARCH_START: "SEARCH_START"
};
