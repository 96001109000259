import { Box, Card, Stack, } from "@mui/material";

import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Outlet } from "react-router-dom";
import MultipleTabs from "./MultipleTabs";


const UpdateCategoryHero = () => {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Outlet />
      <Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mx={3} my={2}>
          <ArgonTypography variant="h4" component="h5">
            Category List Management
          </ArgonTypography>
        </Stack>
        {/* <Box component={"form"} onSubmit={handleSubmit}> */}

        <Box component={"form"} mx={3} sx={{ mt: 1 }}>

          <Stack direction={"row"} spacing={1}>
            <MultipleTabs />
          </Stack>


        </Box>

      </Card>
    </DashboardLayout>
  );
};

export default UpdateCategoryHero;
