import React, { useEffect, useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const AddBudgetModal = ({ open, handleOpen, data }) => {
  const actionDispatcher = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [warning, setWarning] = useState("");

  // ***********************  Stored Value inside textBox  ***********************************

  // const handleChange = (event) => {
  //   setData(event.target.value);
  const handleChange = (event) => {
    const value = event.target.value;

    // Check if the input value has more than 7 digits
    if (value.length <= 7) {
      setInputValue(value);
      setWarning("");
    } else {
      setWarning("Amount too large! Please enter a valid amount");
    }
  };
  // };
  // *********************************************************

  //   // ****************************Advertisement Budget Action Section*********************************
  const setBudget = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.put(`/api/v1/admin/set/advertisement/budget`, {
        advertisementBudget: inputValue,
      });
      // console.log("ActionUser===>", res?.data);
      actionDispatcher(isLoading(false));
      handleOpen();
      actionDispatcher(openSnackbar(res?.data?.message, "success"));
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.log("error===>", error);
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
    }
  };

  // ***************************************************************************************************
  useEffect(() => {
    setInputValue(data);
  }, [data]);

  //

  return (
    <>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              Set Advertisement Budget
            </Typography>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            />
          </Box>

          <Grid container px={3} py={2}>
            <Grid item xs={12} sm={12} md={12}>
              {/* // type="number" // placeholder="Enter Budget" // value={data}
              // onChange={(e) => handleChange(e)}
              /> */}
              <ArgonInput
                type="number"
                value={inputValue}
                onChange={handleChange}
                placeholder="Enter up to 7 digits"
              />
              {warning && (
                <Typography sx={{ color: "red", fontSize: "0.8rem" }}>{warning}</Typography>
              )}
            </Grid>
          </Grid>

          <Grid container px={3}>
            <Grid item xs={12} sm={12} md={12}>
              <ArgonButton variant="gradient" color="success" fullWidth onClick={setBudget}>
                Submit
              </ArgonButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
AddBudgetModal.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  budgetValue: PropTypes.any,
  data: PropTypes.any,
  setData: PropTypes.func,
};

export default AddBudgetModal;
