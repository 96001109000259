import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArgonInput from "components/ArgonInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ArgonButton from "components/ArgonButton";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Modal from "@mui/material/Modal";

//MODAL STYLE
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  borderRadius: 2,
};
// *********************

const NoticeHero = () => {
  const actionDispatcher = useDispatch();
  const [storeQue, setStoreQue] = useState("");
  const [noticeTitle, setNoticeTitle] = useState("");
  const [data, setData] = useState();
  const [open, setOpen] = useState(false);
  const [noticeId, setNoticeId] = useState();

  //   ***************************** Modal Open And Close*****************************************
  const handleOpen = (id) => {
    setOpen(!open);
    setNoticeId(id);
  };
  //   *******************************************************************************************

  //   *******************************Handle Submit Quesion Answer************************************
  const handleSubmit = async (event) => {
    actionDispatcher(isLoading(true));
    event.preventDefault();

    try {
      const response = await axios.post("/api/v1/admin/add/notice", {
        title: noticeTitle,
        notice: storeQue,
      });
      actionDispatcher(isLoading(false));
      //   console.log("mst data upload ho rha hai", response);
      HandleGetData();
      setStoreQue("");
      setNoticeTitle("");
      actionDispatcher(openSnackbar(response?.data?.message, "success"));
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      console.error("Error uploading image:", error);
    }
  };
  //   ***********************************************************************************************
  //   *******************************Handle Get Data Notice************************************
  const HandleGetData = async () => {
    actionDispatcher(isLoading(true));
    try {
      const response = await axios.get("/api/v1/admin/get/all/notices");
      //   console.log("faqData====>", response);
      actionDispatcher(isLoading(false));
      setData(response?.data?.allNotices);
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.error("Error uploading image:", error);
    }
  };
  //   ***********************************************************************************************

  //   *******************************Handle delete Data Notice************************************

  const deleteAccordionItem = async (id) => {
    actionDispatcher(isLoading(true));
    try {
      const response = await axios.delete(`/api/v1/admin/delete/notice/${id}`);
      actionDispatcher(isLoading(false));
      handleOpen(); // Close the modal after successful deletion
      HandleGetData(); // Refresh the FAQ data
      actionDispatcher(openSnackbar(response?.data?.message, "success"));
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.error("Error uploading image:", error);
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
    }
  };
  //   ***********************************************************************************************

  useEffect(() => {
    HandleGetData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card sx={{ padding: "1rem", marginBottom: "1rem" }}>
        <Typography variant="h5">Notice </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} mt={2}>
            <Grid item xs={12} sm={12} md={12}>
              <Typography fontSize={"16px"}>Notice Title </Typography>
              <ArgonInput
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
                placeholder="Enter Notice Title"
                value={noticeTitle}
                multiline
                onChange={(e) => setNoticeTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Typography fontSize={"16px"}>Notice Description</Typography>
              <ArgonInput
                id="outlined-basic"
                label="Outlined"
                variant="outlined"
                placeholder="Enter Notice Discription"
                value={storeQue}
                onChange={(e) => setStoreQue(e.target.value)}
              />
            </Grid>

            <ArgonButton variant="gradient" color="success" sx={{ margin: "1rem" }} type="submit">
              Submit
            </ArgonButton>
          </Grid>
        </form>
      </Card>
      <Card>
        {data &&
          data?.map((item, index) => {
            return (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  {item?.title}
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: "#dee0e3", fontSize: "1rem" }}>
                  {item?.notice}
                </AccordionDetails>
                <AccordionActions sx={{ bgcolor: "#dee0e3" }}>
                  <AccordionActions>
                    {/* <Button onClick={() => deleteAccordionItem(item?._id)}> */}
                    <ArgonButton
                      onClick={() => handleOpen(item?._id)}
                      variant="gradient"
                      color="success"
                      sx={{ margin: "1rem" }}
                    >
                      <DeleteIcon />
                    </ArgonButton>
                    {/* </Button> */}
                  </AccordionActions>
                </AccordionActions>
              </Accordion>
            );
          })}
      </Card>

      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Delete Notice
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: "16px" }}>
            Are you sure to want to delete this notice?
          </Typography>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <ArgonButton
              variant="gradient"
              color="success"
              sx={{ margin: "1rem" }}
              onClick={() => deleteAccordionItem(noticeId)}
            >
              Yes
            </ArgonButton>
            <ArgonButton variant="gradient" color="success" sx={{ margin: "1rem" }} onClick={handleOpen}>
              No
            </ArgonButton>
          </Box>
        </Box>
      </Modal>
      <Footer />
    </DashboardLayout>
  );
};

export default NoticeHero;
