import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { Box } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import axios from "axios";
import ArgonTypography from "components/ArgonTypography";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArgonButton from "components/ArgonButton";
import { useNavigate } from "react-router-dom";

const DisplayContent = () => {
  const navigate = useNavigate();
  const [data, setData] = useState("");

  const getEditorData = async () => {
    try {
      const res = await axios.get(`/api/v1/admin/get/gurash/savings/data`);
      // console.log("SingleUsers Business===>", res?.data);
      setData(res?.data);
    } catch (error) {
      console.log("error===>", error);
    }
  };
  useEffect(() => {
    getEditorData();
  }, []);

  return (
    <PageLayout>
      <Box sx={{ margin: "1rem" }}>
        <Box display={"flex"} justifyContent={"space-between"} my={1}>
          <ArgonTypography>Gurash Saving</ArgonTypography>
          <ArgonButton
            fontSize="large"
            variant="gradient"
            color="secondary"
            onClick={() => navigate(-1)}
          >
            <KeyboardBackspaceIcon sx={{ fontSize: "5rem !important" }} />
          </ArgonButton>
        </Box>
        <CKEditor
          editor={Editor}
          disabled
          data={data}
          config={{
            toolbar: [],
            readOnly: true,
          }}
        />
      </Box>
    </PageLayout>
  );
};

// Setting default values for the props of CoverLayout
DisplayContent.defaultProps = {
  title: "",
  description: "",
  imgPosition: "center",
  button: { color: "white" },
};

// Typechecking props for the CoverLayout
DisplayContent.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imgPosition: PropTypes.string,
  button: PropTypes.object,
  children: PropTypes.node,
};

export default DisplayContent;
