import React, { useEffect, useState } from "react";
import { Box, Grid, Modal, Typography } from "@mui/material";
import PropTypes from "prop-types";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading } from "../../../redux/action/defaultActions";
import ArgonInput from "components/ArgonInput";

import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

const ViewBankDetails = ({ view, handleOpenView }) => {
  const actionDispatcher = useDispatch();
  const [bankDetails, setBankDetails] = useState();

  //   // ****************************Get Bank Details Section*********************************
  const getBankData = async () => {
    try {
      const res = await axios.get(`/api/v1/admin/get/bank/details`);
      // console.log("GetBank details===>", res?.data);
      setBankDetails(res?.data);
    } catch (error) {
      console.log("error===>", error);
    }
  };

  // ***************************************************************************************************
  useEffect(() => {
    getBankData();
  }, []);

  //

  return (
    <>
      <Modal
        open={view}
        onClose={handleOpenView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              View Payment Details
            </Typography>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleOpenView}
            />
          </Box>

          <Grid container px={3} py={2}>
            {bankDetails?.QRcode && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                sx={{ marginTop: "0.5rem" }}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <img
                  src={bankDetails?.QRcode?.imageUrl}
                  alt="OR Code"
                  style={{ objectFit: "contain", width: "100%", height: "100%" }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={12} display={"flex"}>
              <Typography sx={{ fontSize: "0.9rem" }}>Account Number :</Typography>
              <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
                &nbsp;
                {bankDetails?.bankDetails?.accountNo}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} display={"flex"}>
              <Typography sx={{ fontSize: "0.9rem" }}>Account Holder Name :</Typography>
              <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
                &nbsp;
                {bankDetails?.bankDetails?.accountHolderName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} display={"flex"}>
              <Typography sx={{ fontSize: "0.9rem" }}>Bank Name :</Typography>
              <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
                &nbsp;
                {bankDetails?.bankDetails?.bankName}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} display={"flex"}>
              <Typography sx={{ fontSize: "0.9rem" }}>IFSC Code :</Typography>
              <Typography sx={{ fontSize: "0.9rem", fontWeight: "600" }}>
                &nbsp;
                {bankDetails?.bankDetails?.ifscCode}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};
ViewBankDetails.propTypes = {
  view: PropTypes.bool,
  handleOpenView: PropTypes.func,
};

export default ViewBankDetails;
