import { Box, CardContent, Grid, Modal, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

// Style of Modal section set in center
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  maxHeight: "90vh",
  overflowY: "auto",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};
const styleBtn = {
  margin: "1rem",
  bgcolor: "#42d7f5 !important",
  color: "white !important",
};
// ******************************

const SingleTransaction = ({ open, handleOpen, data }) => {
  // console.log("InsideTransactionData====>", data);
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} overflowy={"auto"}>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            />
          </Box>
          {data?.advertisementImage ? (
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "20rem",
                // objectFit: "cover",
                marginBottom: "1rem",
              }}
            >
              <img
                src={data?.advertisementImage?.imageUrl}
                alt={data?.userName}
                style={{
                  borderRadius: "5px",
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
              />
            </CardContent>
          ) : (
            ""
          )}

          <CardContent>
            <Grid container>
              <Grid item container xs={12} sm={12} md={12} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Advertisement Category :
                </Typography>

                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.advertisementCategory}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Advertisement Type :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.advertisementType}
                </Typography>
              </Grid>
              {data?.advertisementType === "website" || data?.advertisementType === "Biz Page" ? (
                <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                  <Typography variant="h6" component="div">
                    Title :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    {data?.title}
                  </Typography>
                </Grid>
              ) : (
                ""
              )}

              <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Budget :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  रू{data?.budget}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Status :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.status}
                </Typography>
              </Grid>

              {/* <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Gender :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.gender}
                </Typography>
              </Grid> */}
              <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                <Typography variant="h6" component="div">
                  Schedule :
                </Typography>
                <Typography variant="h6" component="div" color="text.secondary">
                  {data?.schedule}
                </Typography>
              </Grid>

              {data?.schedule === "start later" ? (
                <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                  <Typography variant="h6" component="div">
                    Schedule Date :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    {/* {data?.address?.city} {data?.address?.state} {data?.address?.country} */}
                    {dayjs(data?.scheduleStartDate).format("DD/MM/YY")} &nbsp;to &nbsp;
                    {dayjs(data?.scheduleEndDate).format("DD/MM/YY")}
                  </Typography>
                </Grid>
              ) : (
                // "scheduleDays"
                <Grid item xs={12} sm={11} md={6} display={"flex"} overflow={"auto"}>
                  <Typography variant="h6" component="div">
                    Schedule Days:
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    {data?.scheduleDays}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Box>
      </Modal>
    </Box>
  );
};

SingleTransaction.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func.isRequired,
  data: PropTypes.object,
  rowsPerPage: PropTypes.any,
  page: PropTypes.any,
};

export default SingleTransaction;
