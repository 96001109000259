// import React from "react";

// const VerificationUserDetails = () => {
//   return <div>VerificationUserDetails</div>;
// };

// export default VerificationUserDetails;
import { Box, Button, CardContent, Modal, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import axios from "axios";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";
import imgavatar from "../../../assets/images/AdminSection/avatar.jpg";

// Style of Modal section set in center
const style = {
  position: "absolute",
  overflowY: "auto",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};
// ******************************

const VerificationUserDetails = ({ open, handleOpen, data }) => {
  console.log("data", data);
  const actionDispatcher = useDispatch();
  const [selectedImage, setSelectedImage] = useState(null);
  //   console.log("VerificationSection===>", data);

  // ************************** Post Verification section data Accept OR Rejected *******************************

  const handleAction = async (status, _id) => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.put(
        `/api/v1/admin/update/user/verification/details/for/used/vehicles/${_id}`,
        { status }
      );
      //   console.log("dropdownSelectedValue=====>", res?.data);
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(res?.data?.message, "success"));
      handleOpen();
    } catch (error) {
      actionDispatcher(isLoading(false));
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
      handleOpen();
      console.log("error", error);
    }
  };

  // **************************************************************************************

  return (
    <Box>
      <Modal
        open={open}
        onClose={handleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} overflowY="scroll">
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleOpen}
            />
          </Box>
          {data?.category === "used vehicles" ? (
            <Box>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  className="image-container"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={data?.licenceSelfieImage?.imageUrl}
                    alt="licenceSelfieImage"
                    className="image"
                    style={{
                      objectFit: "cover",
                      height: "20%",
                      width: "50%",
                      maxHeight: "300px",
                      borderRadius: "5px",
                    }}
                  />
                  <img
                    src={data?.licenseImage?.imageUrl}
                    alt="licenseImage"
                    className="image"
                    style={{
                      objectFit: "cover",
                      height: "20%",
                      width: "50%",
                      maxHeight: "300px",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
              </CardContent>
              <CardContent>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    Name :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp; {data?.ownerName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    License Number :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp;{data?.licenseNumber}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    Vehicle Number :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp; {data?.vehicleNumber}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    CreatedAt :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp; {dayjs(data?.createdAt).format("DD/MM/YY")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    Status :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp; {data?.status}
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          ) : (
            <Box sx={{ overflow: "auto", maxHeight: "450px" }}>
              <CardContent
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <MyCarousel data={imgData} /> */}
                <Box
                  className="image-container"
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={
                      data?.citizenshipSelfieWithCardPhoto?.imageUrl
                        ? data?.citizenshipSelfieWithCardPhoto?.imageUrl
                        : { imgavatar }
                    }
                    alt="SelfieImage"
                    className="image"
                    style={{
                      objectFit: "cover",
                      height: "20%",
                      width: "50%",
                      maxHeight: "300px",
                      borderRadius: "5px",
                    }}
                  />
                  <img
                    src={
                      data?.citizenshipSelfieWithCardPhoto?.imageUrl
                        ? data?.citizenshipSelfieWithCardPhoto?.imageUrl
                        : { imgavatar }
                    }
                    alt="citizenImage"
                    className="image"
                    style={{
                      objectFit: "cover",
                      height: "20%",
                      width: "50%",
                      maxHeight: "300px",
                      borderRadius: "5px",
                    }}
                  />
                </Box>
              </CardContent>
              <CardContent>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    Name :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp; {data?.user?.userName}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    Citizenship Cardnumber :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp;{data?.citizenshipCardnumber}
                  </Typography>
                </Box>

                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    CreatedAt :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp; {dayjs(data?.createdAt).format("DD/MM/YY")}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Typography variant="h6" component="div">
                    Status :
                  </Typography>
                  <Typography variant="h6" component="div" color="text.secondary">
                    &nbsp; {data?.status}
                  </Typography>
                </Box>
              </CardContent>
            </Box>
          )}

          {data?.status === "pending" ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Button
                variant="contained"
                sx={{ margin: "0.5rem", bgcolor: "#3EC787", color: "white !important" }}
                onClick={() => handleAction("approved", data?._id)}
              >
                Accept
              </Button>

              <Button
                variant="contained"
                sx={{ margin: "0.5rem", bgcolor: "#EA3636", color: "white !important" }}
                onClick={() => handleAction("rejected", data?._id)}
              >
                Reject
              </Button>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Modal>
    </Box>
  );
};

VerificationUserDetails.propTypes = {
  open: PropTypes.bool,
  handleOpen: PropTypes.func,
  data: PropTypes.object,
  refreshData: PropTypes.func,
};

export default VerificationUserDetails;
