import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { isLoading, openSnackbar } from '../../../../../redux/action/defaultActions';
import { Box, MenuItem, TextField } from '@mui/material';
import NestedDropdown from './NestedDropdown';

const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const ListDeletechip = () => {
    const actionDispatcher = useDispatch();
    const [category, setCategory] = useState("part time job");
    const [mainData, setMainData] = useState([])
    const [optionData, setOptionData] = useState([]);

    // const handleDelete = (id) => () => {

    //     // setChipData((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    // };
    //   *******************************Handle delete Data Quesion Answer FAQ************************************

    const handleDelete = async (id) => {

        actionDispatcher(isLoading(true));
        try {
            const response = await axios.delete(`/api/v1/admin/get/list/of/any/category/${id}`);
            actionDispatcher(isLoading(false));
            // handleOpen(); // Close the modal after successful deletion
            getMainData(); // Refresh the FAQ data
            actionDispatcher(openSnackbar(response?.data?.message, "success"));
        } catch (error) {
            actionDispatcher(isLoading(false));
            console.error("Error uploading image:", error);
            actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
        }
    };
    //   ***********************************************************************************************

    // ************************** Get main category data of Dropdown Section *******************************

    const getMainCategory = async () => {
        actionDispatcher(isLoading(true));
        try {
            const res = await axios.get(`/api/v1/admin/get/main/categories`);
            // console.log("resssmaincategory=====>", res?.data?.allMainCategories);
            actionDispatcher(isLoading(false));
            setOptionData(res?.data?.allMainCategories);
        } catch (error) {
            actionDispatcher(isLoading(false));
            actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
            console.log("error", error);
        }
    };
    // **************************************************************************************

    // *************************** ON selected value  through drpdown *******************************

    const handleChange = async (event) => {
        setCategory(event.target.value);
        getMainData(event.target.value)

    };
    // **********************************************************


    //   ******************************* Handle Get Data Main Category ************************************
    const getMainData = async (value) => {
        // console.log("=====category====>", value)
        try {
            const response = await axios.get(`api/v1/admin/get/all/lists/of/any/category/${value}`);
            // console.log("mainCategory====>", response?.data?.allLists);
            setMainData(response?.data?.allLists);
        } catch (error) {
            console.error("Error uploading image:", error);
        }
    };
    //   ***********************************************************************************************

    useEffect(() => {

        getMainCategory();
    }, []);

    return (
        <Paper
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
                listStyle: 'none',
                p: 0.5,
                m: 0,
            }}
            component="ul"
        >

            <TextField
                sx={{ textTransform: "capitalize", marginY: "1rem" }}
                onChange={handleChange}
                select
                value={category}
            >

                {optionData?.map((option) => {
                    return (
                        <MenuItem
                            key={option._id}
                            sx={{ textTransform: "capitalize" }}
                            value={option?.category}
                        >
                            {option?.category}
                        </MenuItem>
                    );
                })}
            </TextField>
            <NestedDropdown mainData={mainData} />


        </Paper>
    );
}


export default ListDeletechip;



