import React from "react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  MobileStepper,
  Paper,
  Typography,
  Grid,
  Stack,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import ArgonBox from "components/ArgonBox";
import { autoPlay } from "react-swipeable-views-utils";
import SwipeableViews from "react-swipeable-views";
import dayjs from "dayjs";
import "./SingleData.css";

const RealState = (data) => {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  // console.log("data", data?.data);
  const content = data?.data?.productData;
  const imagesToDisplay = [
    ...data?.data?.productData?.imagesForSale,
    ...data?.data?.productData?.imagesOfFloor,
  ];
  const number = data?.data?.productData?.countryCode + data?.data?.productData?.phoneNumber;
  const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };
  return (
    <>
      <Card>
        <ArgonBox>
          <Grid
            container
            sx={{
              flexGrow: 1,
              width: "100%",
            }}
          >
            <Grid item xs={12}>
              <Typography p={2}>{content?.category}</Typography>

              <AutoPlaySwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={activeStep}
                // onChangeIndex={handleStepChange}
                // enableMouseEvents
              >
                {imagesToDisplay.length > 0 &&
                  imagesToDisplay?.map((step, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        height: "350px",
                        padding: "1rem",
                      }}
                    >
                      {Math.abs(activeStep - index) <= imagesToDisplay.length ? (
                        <Box
                          component="img"
                          sx={{
                            maxHeight: "100%",
                            display: "block",
                            overflow: "hidden",
                            width: "auto",
                            objectFit: "cover",
                            borderRadius: "5px",
                          }}
                          src={step.imageUrl}
                          alt={step.public_id}
                        />
                      ) : null}
                    </Box>
                  ))}
              </AutoPlaySwipeableViews>
              <MobileStepper
                // steps={maxSteps}
                steps={imagesToDisplay?.length > 0 ? imagesToDisplay.length : 0}
                position="static"
                activeStep={activeStep}
                nextButton={
                  <Button
                    size="small"
                    onClick={handleNext}
                    disabled={activeStep === imagesToDisplay.length - 1}
                  >
                    Next
                    {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                  </Button>
                }
                backButton={
                  <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                    {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                    Back
                  </Button>
                }
              />
            </Grid>
          </Grid>

          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={7} md={7}>
                <Typography className="header-text-details">Title</Typography>
                <Item>{content?.type}</Item>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <Typography className="header-text-details">Contact-No</Typography>
                <Item>{number}</Item>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className="header-text-details">Price</Typography>
                <Item> रू{content?.charges}</Item>
              </Grid>
              <Grid item xs={12} sm={9} md={9}>
                <Typography className="header-text-details">Address</Typography>
                <Item>
                  {content?.address?.wardName} ,{content?.address?.wardNumber}
                </Item>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <Typography className="header-text-details">Created-At</Typography>
                <Item>{dayjs(content?.createdAt).format("DD/MM/YY")}</Item>
              </Grid>
              <Grid item xs={12} sm={7} md={7}>
                <Typography className="header-text-details">Category</Typography>
                <Item>{content?.category}</Item>
              </Grid>

              <Grid item xs={12} sm={5} md={5}>
                <Typography className="header-text-details">AccomodationType</Typography>
                <Item>{content?.accomodationType}</Item>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className="header-text-details">sqft</Typography>
                <Item>{content?.buildUpArea}</Item>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography className="header-text-details">posting Type</Typography>
                <Item>{content?.postingType}</Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography className="header-text-details">Landmark</Typography>
                <Item>{content?.landmark}</Item>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className="header-text-details">Building Floor</Typography>
                <Item>{content?.entireBuildingFloor}</Item>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <Typography className="header-text-details">Floor-No</Typography>
                <Item>{content?.movingFloor}</Item>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Typography className="header-text-details">Facilities</Typography>
                <Item>
                  {content?.facilities?.map((value, index) => {
                    return (
                      <Chip
                        key={index}
                        label={value}
                        color="primary"
                        size="small"
                        variant="filled"
                      />
                    );
                  })}
                </Item>
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Typography className="header-text-details">Additional Charge</Typography>
                <Item>{content?.additionalCharge}</Item>
              </Grid>
              {/* <Grid item xs={12} sm={3} md={3}>
                  <Typography className="header-text-details">Floor-No</Typography>
                  <Item>{content?.movingFloor}</Item>
                </Grid> */}
              <Grid item xs={12} sm={6} md={6}>
                <Typography className="header-text-details">Management Fee Category</Typography>
                <Item> {content?.managementFeeCategory}</Item>
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography className="header-text-details">Direction</Typography>
                <Item>{content?.direction}</Item>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <Typography className="header-text-details">Parking</Typography>
                {content?.parking ? <Item>Yes</Item> : ""}
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <Typography className="header-text-details">Pets</Typography>
                {content?.pets ? <Item>Yes</Item> : ""}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography className="header-text-details">Dicription</Typography>
                <Item>{content?.additionalInfo}</Item>
              </Grid>
            </Grid>
          </CardContent>
        </ArgonBox>
      </Card>
    </>
  );
};

export default RealState;
