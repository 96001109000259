import { Box, Card, CircularProgress } from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Table from "../../../examples/Tables/Table";
// import projectsTableData from "layouts/tables/data/projectsTableData";
import React, { useEffect, useState } from "react";
import TransactionTableData from "./TransactionTableData";
import { useSelector } from "react-redux";
import ArgonButton from "components/ArgonButton";
import AddPaymentModal from "./AddPaymentModal";
import ViewBankDetails from "./ViewBankDetails";

const TransactionMainTable = () => {
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { search } = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(false);
  const handleOpen = () => setOpen(!open);

  const handleOpenView = () => setView(!view);

  const handleChangePage = (event, newPage) => {
    // console.log("pageChange===>", event);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("====", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    if (page >= 0 && rowsPerPage) {
      TransactionTableData(page, rowsPerPage, search.query).then(
        ({ columns, rows, noOfTransactions, noOfPages }) => {
          setTableData({ columns, rows, noOfTransactions, noOfPages });
        }
      );
    }
  }, [page, rowsPerPage, search.query]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Card>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <ArgonTypography variant="h5"> All Transaction </ArgonTypography>
            <Box>
              <ArgonButton
                variant="gradient"
                color="success"
                onClick={handleOpenView}
                sx={{ marginX: "1rem" }}
              >
                View Details
              </ArgonButton>
              <ArgonButton variant="gradient" color="success" onClick={handleOpen}>
                Add Payment Details
              </ArgonButton>
            </Box>
            <ViewBankDetails view={view} handleOpenView={handleOpenView} />
            <AddPaymentModal open={open} handleOpen={handleOpen} />
          </ArgonBox>
          <ArgonBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            {tableData ? (
              <Table
                columns={tableData?.columns}
                rows={tableData?.rows}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleChangePage={handleChangePage}
                noOfPages={tableData.noOfPages ? tableData.noOfPages : 0}
                noOfUsers={tableData.noOfTransactions ? tableData.noOfTransactions : 0}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <CircularProgress />
              </Box>
            )}
          </ArgonBox>
        </Card>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
};

export default TransactionMainTable;
