import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import ArgonButton from "components/ArgonButton";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { isLoading, openSnackbar } from "../../../redux/action/defaultActions";

import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
};

export default function DeleteUserModal({ dleModal, handleDelete, id }) {
  const actionDispatcher = useDispatch();
  //   // ****************************Advertisement Budget Action Section*********************************
  const handleDeleteUser = async () => {
    actionDispatcher(isLoading(true));
    try {
      const res = await axios.delete(`/api/v1/admin/delete/user/${id}`);
      // console.log("ActionUser===>", res?.data);
      actionDispatcher(isLoading(false));
      handleDelete();
      window.location.reload();
      actionDispatcher(openSnackbar(res?.data?.message, "success"));
    } catch (error) {
      actionDispatcher(isLoading(false));
      console.log("error===>", error);
      actionDispatcher(openSnackbar(error?.response?.data?.message, "error"));
    }
  };

  // ***************************************************************************************************
  return (
    <div>
      <Modal
        open={dleModal}
        onClose={handleDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="modal-modal-title" variant="h5" component="h2">
              User Delete
            </Typography>
            <CloseIcon
              sx={{
                border: "1px solid grey",
                borderRadius: "50%",
                padding: "2px",
                cursor: "pointer",
              }}
              onClick={handleDelete}
            />
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are You sure to want to delete this user!
          </Typography>
          <Grid container px={3} spacing={3}>
            <Grid item xs={6} sm={6} md={6}>
              <ArgonButton variant="gradient" color="success" fullWidth onClick={handleDelete}>
                NO
              </ArgonButton>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <ArgonButton variant="gradient" color="error" fullWidth onClick={handleDeleteUser}>
                YES
              </ArgonButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
DeleteUserModal.propTypes = {
  id: PropTypes.string,
  dleModal: PropTypes.bool,
  handleDelete: PropTypes.func,
};
