/**
=========================================================
* Gurash Dahboard MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef, useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for ArgonInput
import ArgonInputRoot from "components/ArgonInput/ArgonInputRoot";

// Gurash Dahboard MUI context
import { useArgonController } from "context";

const ArgonInput = forwardRef(({ size, error, success, disabled, type, ...rest }, ref) => {
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const [showPassword, setShowPassword] = useState(false);

  return (
    <ArgonInputRoot
      {...rest}
      ref={ref}
      type={type === "password" ? (showPassword ? "text" : "password") : type}
      endAdornment={
        type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              // onClick={() => (this.type = "text")}
              onMouseDown={() => setShowPassword(!showPassword)}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }
      ownerState={{ size, error, success, disabled, darkMode }}
    />
  );
});

// Setting default values for the props of ArgonInput
ArgonInput.defaultProps = {
  size: "medium",
  error: false,
  success: false,
  disabled: false,
};

// Typechecking props for the ArgonInput
ArgonInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  type: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default ArgonInput;
