import Card from "@mui/material/Card";

// Gurash Dahboard MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Gurash Dahboard MUI examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";

// Data
import authorsTableData from "layouts/tables/data/authorsTableData";

import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";

function Tables() {
  // const { columns: prCols, rows: prRows } = projectsTableData;
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { search } = useSelector((state) => state);

  const handleChangePage = (event, newPage) => {
    // console.log("pageChange===>", event);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    // console.log("====", event.target.value);
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  useEffect(() => {
    authorsTableData(page, rowsPerPage, search.query).then(
      ({ columns, rows, noOfUsers, noOfPages }) => {
        setTableData({ columns, rows, noOfUsers, noOfPages });
      }
    );
  }, [page, rowsPerPage, search.query]);

  return (
    <DashboardLayout>
      <DashboardNavbar data={tableData} setData={setRowsPerPage} />
      <ArgonBox py={3}>
        <ArgonBox mb={3}>
          <Card>
            <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <ArgonTypography variant="h5">User Management</ArgonTypography>
            </ArgonBox>
            <ArgonBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              {tableData ? (
                <>
                  <Table
                    columns={tableData.columns}
                    rows={tableData.rows}
                    page={page}
                    setPage={setPage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    handleChangePage={handleChangePage}
                    noOfPages={tableData.noOfPages}
                    noOfUsers={tableData.noOfUsers}
                  />
                </>
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <CircularProgress />
                </Box>
              )}
            </ArgonBox>
          </Card>
        </ArgonBox>
        {/* <Card>
          <ArgonBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <ArgonTypography variant="h6">Projects table</ArgonTypography>
          </ArgonBox>
          <ArgonBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={prRows} />
          </ArgonBox>
        </Card> */}
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
